.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 10px;
  max-height: 98%;
  max-width: 600px;
  overflow-y: scroll;
  animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
@media screen and (max-width: 500px) {
  .popup-content {
    width: 90%;
  }
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(9, 1, 58, 0.451);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.modal {
}
.header {
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  color: var(--transparent);
}
.modal > .content {
  width: 100%;
}
.modal img {
  object-fit: contain;
  height: 100%;
}
