.comment-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.comment-container .section {
  width: 100%;
}
.comment-form {
  width: 500px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  background-color: var(--white);
  position: relative;
}
.comment-input {
  display: block;
  width: 100%;
  height: 200px;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--black);
  border: 1px solid var(--transparent);
  background: var(--white);
  margin: 4px 0;
  transition: border 0.3s ease;
}

/* Comment Card design */
.grid-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
}
.slider-item {
  width: 300px;
  height: max-content;
  padding: 4px;
  border-radius: 10px;
  background-color: var(--white-to-gray);
  display: flex;
  justify-content: flex-start;
  overflow-wrap: break-word;
}

.slider-item .animation-card_content {
  width: 100%;
  max-width: 374px;
  padding: 4px;
}
.slider-item .animation-card_content .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-item .animation-card_content .animation-card_content_title {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 24px;
  margin: 0;
}
.slider-item .animation-card_content .animation-card_content_description {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 24px;
  margin: 10px 0 0 0;
  width: 100%;
}
.slider-item .animation-card_content .animation-card_content_see_more {
  font-size: 11px;
  margin: 10px 0 0 0;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding: 4px;
}

/* Comment Card design */

@media screen and (max-width: 520px) {
  .comment-form {
    width: 400px;
  }
}
@media screen and (max-width: 420px) {
  .comment-form {
    width: 300px;
  }
}
@media screen and (max-width: 320px) {
  .comment-form {
    width: 250px;
  }
}
