.historique-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 20px;
  justify-content: center;
  padding: 20px;
}
.historique-container .card {
  background-color: var(--white);
  padding: 10px;
  height: max-content;
}
.historique-container .card .bottom {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  grid-gap: 10px;
  justify-content: space-between;
}
