.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.faq {
  width: 70%;
  padding: 4px 0;
}
@media screen and (max-width: 900px) {
  .faq {
    width: 90%;
  }
}
.accordion {
  background-color: var(--white);
  border-radius: 4px;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s;
}

.panel {
  padding: 0 18px;
  border-radius: 4px;
  background-color: var(--white);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.reveal {
  background-color: var(--white);
  color: var(--black);
  max-height: 300px;
  overflow-y: scroll;
  transition: max-height 0.2s ease-out;
}
