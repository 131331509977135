.navbarTop {
  height: 80px;
  width: 100%;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  z-index: 99;
}
.navbarBottom {
  height: 80px;
  width: 100%;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  position: fixed;
  bottom: 0;
  z-index: 99;
  overflow-x: scroll;
}
.shadow {
  box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
}

.nav-links {
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
}
.nav-link {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  margin: 0 4px;
  color: var(--black);
  transform: scale(0.9);
  transition: transform 330ms ease-in-out;
}
.active-link {
  background-color: var(--first-color);
  color: var(--white);
  box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 5px 41px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px) scale(1.1);
  transition: transform 330ms ease-in-out;
}
.nav-logo {
  width: 65px;
  position: relative;
}

.nav-text {
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  width: max-content;
}
.btn-text {
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .nav-text {
    font-size: 14px;
  }
}
.nav-logo img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.btn-icon {
  margin-right: 8px;
}

.logo-top {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background-color: var(--white);
  z-index: 99;
}
.logo-top .logo-link {
  height: 100%;
}
.logo-top .logo-link img {
  height: 100%;
  pointer-events: none;
}
