.home-header {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: var(--first-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 600px) {
  .home-header,
  .form-container {
    height: calc(100vh - 130px);
  }
}

.w-100 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.home-content h3 {
  color: var(--first-color);
}
