.footer {
  background-color: var(--footer);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 10px;
  color: #efefef;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 600px) {
  .footer {
    margin-bottom: 80px;
  }
}
.footer .grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  grid-gap: 40px;
  justify-content: space-around;
}

.footer .grid .block {
  display: flex;
  flex-direction: column;
}
.footer .grid .block .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .grid .block .row img {
  width: 35px;
}
.footer .grid .block .title {
  font-weight: bold;
  font-size: 18px;
}
.links {
  text-decoration: none;
  color: var(--white-2);
  margin: 10px 0;
}

.copyright {
  margin-top: 20px;
}
