.admin-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.admin-container .head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.add-form {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 1px à 40px rgba(0, 0, 0, 0.04);
  margin-top: 80px;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
.add-form h1 {
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;
}
.add-form label {
  width: 100%;
}

.add-form label .add-input {
  width: 100%;
  padding: 12px 4px;
  outline: 0;
  border: 1px solid var(--transparent);
  margin: 12px 0;
}

@media screen and (max-width: 520px) {
  .add-form {
    width: 400px;
  }
}

@media screen and (max-width: 410px) {
  .add-form {
    width: 350px;
  }
  .admin-container .head {
    flex-direction: column;
  }
}
@media screen and (max-width: 350px) {
  .add-form {
    width: 300px;
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.menu-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  grid-gap: 40px;
  justify-content: center;
  margin: 20px 0;
}
.user-container {
  padding: 20px;
}
.user-card {
  background-color: var(--white);
  padding: 10px;
}

/* Table */
.table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  padding: 10px;
}
.table-container h1 {
  font-size: 30px;
  color: var(--black);
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
}

table {
  width: 100%;
  background-color: var(--white);
  border-collapse: collapse;
}
table th,
table td {
  border: 1px solid var(--second-color);
}
thead {
  background-color: var(--first-color);
}
thead tr th {
  cursor: pointer;
}
.tbody {
  height: 300px;
  overflow-x: auto;
  margin-top: 0px;
}

th {
  padding: 20px 15px;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}
td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 12px;
  width: max-content;
  color: var(--black);
  border-bottom: solid 1px rgba(65, 65, 65, 0.1);
}
.pagination-bar {
  display: flex;
  list-style-type: none;
}
.pagination-bar li {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: var(--black);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
  cursor: pointer;
}
.pagination-container li:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container li:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.row-admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  border-bottom: 1px solid var(--transparent);
  padding: 10px 0;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
