.form-container {
  display: flex;
  padding: 20px;
  justify-content: center;
}
.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}
.login-form {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 1px 0 40px rgba(0, 0, 0, 0.04);
  position: relative;
}
.login-form h1,
.login-form h2 {
  font-weight: 600;
  text-align: center;
}
.login-form label {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: 6px 0;
  padding: 0;
}
.input-icon {
  height: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-icon.pointer {
  cursor: pointer;
}

.login-form label .login-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--black);
  border: 1px solid var(--transparent);
  background: var(--white);
  margin: 4px 0;
  transition: border 0.3s ease;
  position: relative;
}
.login-input:focus {
  outline: none;
  border-color: var(--first-color);
}
.login-form label .login-input input {
  outline: none;
  border: none;
  background: none;
  width: 100%;
  background: none;
  padding: 8px 4px;
}

@media screen and (max-width: 520px) {
  .login-form {
    width: 400px;
  }
}

@media screen and (max-width: 410px) {
  .login-form {
    width: 300px;
  }
}
