.verify-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  grid-gap: 20px;
  justify-content: center;
  margin-top: 20px;
}
.list-container {
  background-color: var(--white);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--black);
}
.card-container {
  padding: 10px;
}

.verify-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
}
.verify-form {
  background-color: var(--white);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: max-content;
  align-self: center;
}
.verify-form .val-input,
.val-select {
  width: 50%;
}
.list-view {
  background-color: var(--white);
  padding: 4px 30px;
  color: var(--black);
}

@media screen and (max-width: 800px) {
  .verify-form .val-input,
  .val-select {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .verify-form {
    padding: 10px;
  }
  .verify-form .val-input,
  .val-select {
    width: 95%;
  }
}
