@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --black: #121212;
  --footer: #020c27;
  --first-color: #0a47a2;
  --second-color: #427aa1;
  --white: #fff;
  --white-2: #fafafa;
  --white-text: #fff;
  --transparent: rgba(0, 0, 0, 0.432);
  --white-to-gray: #fff;
  --body-color: #b3b3b3;
  --body-bg-color: #ebf2fa;
  --theme-bg-color: #fafafa;
  --border-color: #efefef;
  --logo-color: #f13a2f;
  --light-green: #3cf12fbc;
  --light-red: #f1392fbc;
  --main-color: #808080;
  --header-bg-color: #fff;
  --invert: invert(0);
}

* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
  word-wrap: break-word;
}
::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  background-color: var(--body-bg-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.overlay {
  position: absolute;
  height: 100vh;
  inset: 0;
  background-color: var(--transparent);
  z-index: -1;
}
.w-100 {
  width: "100%";
}
.page-content {
  margin-top: 80px;
  color: var(--black);
  position: relative;
  overflow-x: hidden;
  flex: 1 0 auto;
}
@media screen and (max-width: 600px) {
  .page-content {
    margin-top: 50px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.loading img {
  width: 100px;
}

.error,
.unsubscribe {
  color: red;
}
a {
  text-decoration: none;
}

.display-none {
  display: none;
}

.load-overlay {
  background-color: var(--white);
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
}
.load-overlay img {
  width: 65px;
}
.error {
  color: red;
}
.success {
  color: green;
  text-align: center;
  padding: 10px;
}

.button {
  display: flex;
  background-color: var(--first-color);
  align-items: center;
  border: 0;
  border-radius: 25px;
  color: var(--white-text);
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  width: max-content;
  height: max-content;
}
.button2 {
  display: flex;
  background-color: var(--first-color);
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  color: var(--white-text);
  padding: 8px 16px;
  margin: 4px;
  cursor: pointer;
  font-size: 14px;
  width: max-content;
}
.logout {
  display: flex;
  background-color: transparent;
  align-items: center;
  border: 0;
  color: red;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  width: max-content;
  height: max-content;
}
.icon {
  width: 35px;
  cursor: pointer;
}

.status-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  left: 10px;
  width: max-content;
  z-index: 99;
  color: var(--white);
  border-radius: 50%;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  .status-container {
    bottom: 80px;
  }
}

.whatsapp {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: max-content;
  z-index: 99;
  color: var(--white);
  border-radius: 50%;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .whatsapp {
    bottom: 80px;
  }
}
.code-parrain {
  padding: 10px;
  cursor: pointer;
}
.close {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  font-size: 30px;
  background: var(--light-red);
  color: var(--white);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: 0;
}
.options {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  font-size: 30px;
  background: transparent;
  color: var(--black);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: 0;
}
.small {
  font-size: 10px;
}
.search-input {
  -webkit-appearance: none;
  padding: 7px 40px 7px 12px;
  width: 300px;
  border: 0;
  border-radius: 5px;
  background: white;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  outline: 0;
  transition: all 150ms ease;
}
textarea {
  resize: none;
}
.variable {
  color: rgb(255, 98, 0);
}
.refresh-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.refresh {
  background-color: var(--first-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  color: var(--white);
  padding: 8px 16px;
  cursor: pointer;
  font-size: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.pointer {
  cursor: pointer;
  padding: 4px;
}
.white {
  background-color: var(--white);
  padding: 20px;

  position: relative;
}
.icon {
  filter: var(--invert);
}

.notif-icon {
  background-color: green;
  color: var(--white-2);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}

.status {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 10px;
}
.status.online,
.status.online:before {
  background: #72ff7d;
}
.status.offline,
.status.offline:before {
  background: #ff4242;
}
.status:before {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in;
}
.status-text {
}
@keyframes pulse {
  from {
    transform: scale(0.5);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.notfound-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 50px;
}
.notfound-container p {
  font-size: large;
}
.notfound-container input {
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 10px 14px;
  background-color: var(--first-color);
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.title {
  padding: 30px 30px 0;
  text-align: center;
  margin: 0;
  font-weight: 500;
  line-height: 1.1;
}

/* Grid & card */
.grid {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
}
.card {
  position: relative;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  width: 300px;
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  animation: show 0.4s ease;
}
.card2 {
  position: relative;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  animation: show 0.4s ease;
}
.card .top {
  background-color: var(--white-to-gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}
.card .top img {
  width: 80px;
  border-radius: 8px;
}
.card .top p {
  padding: 2px;
}
.card .price {
  margin: 0 5px;
  padding: 5px;
}
.card .bottom {
  background-color: var(--second-color);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 8px;
  height: 75px;
}
@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
