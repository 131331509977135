.home-historique-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4px;
}

.historique-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 500px;
  padding: 0 6px;
  border-radius: 4px;
  margin: 2px 0;
}
.historique-row:nth-of-type(odd) {
  background-color: var(--second-color);
  color: var(--white);
}

.historique-row:nth-of-type(odd) .exchange-icon {
  filter: invert(1);
}
.historique-row:nth-of-type(even) {
  background-color: var(--white-to-gray);
}
.historique {
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.historique h1 {
  font-size: 1px;
  margin-right: 10px;
}

.historique img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.historique-data img {
  height: 25px;
  width: 25px;
  margin-right: 6px;
}

.historique-data {
  display: flex;
  text-align: left;
  justify-content: space-between;
  text-transform: lowercase;
}

.historique-volume {
  width: 155px;
}

.historique-percent {
  padding: 0 10px;
}

.red {
  color: #f00606;
}

.green {
  color: #21a421;
}

@media screen and (max-width: 560px) {
  .historique-row {
    width: 100%;
  }
}
