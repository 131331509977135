.exchenge {
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  width: 94%;
  border-radius: 6px;
  height: max-content;
}

.exchenge h1 {
  padding: 0;
  margin: 0;
}
.exchenge .inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}
.exchenge .inputs p {
  font-size: 22px;
}

.exchenge .inputs .left,
.exchenge .inputs .right {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
}
.exchenge .inputs .left p,
.exchenge .inputs .right p {
  margin: 6px;
}
.val-input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--black);
  border: 1px solid var(--transparent);
  background: var(--white);
  margin-top: 6px;
  transition: border 0.3s ease;
}
.val-input::-moz-placeholder {
  color: var(--body-color);
}
.val-input:-ms-input-placeholder {
  color: var(--body-color);
}
.val-input::placeholder {
  color: var(--body-color);
}
.val-input:focus {
  outline: none;
  border-color: var(--first-color);
}
.infos {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.info-1 {
  display: flex;
}
.info-1 p {
  margin: 0 20px;
}

@media screen and (max-width: 610px) {
  .exchenge .inputs {
    flex-direction: column;
  }
  .exchenge .inputs .left,
  .exchenge .inputs .right {
    width: 100%;
    padding: 0;
  }
}

.transaction-container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.transaction-container .bigcard {
  width: 90%;
  background-color: var(--white);
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 10px;

  animation: show 0.4s ease;
}
.confirm-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.confirm-form label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm-input {
  width: 60%;
  padding: 12px 4px;
  outline: 0;
  border: 1px solid var(--transparent);
  margin: 12px 0;
}
.condition {
  margin-right: 20px;
}
.confirm-form .bottom {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}
.checkbox {
  min-width: 0.75em;
  min-height: 0.75em;
  margin-right: 0.75em;
  border: 2px solid var(--first-color);
  border-radius: 50%;
  display: inline-block;
}
@media screen and (max-width: 700px) {
  .confirm-form label {
    flex-direction: column;
  }
  .confirm-input {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .transaction-container .card {
    width: 100%;
  }
  .confirm-form .bottom {
    flex-direction: column;
  }
  .condition {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 380px) {
  .transaction-container {
    padding: 20px 10px;
  }
}

.switch-container {
  width: 260px;
  height: 60px;
  margin: 0 0 6px 0;
}
