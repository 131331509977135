* {
  outline: none;
  box-sizing: border-box;
}
img {
  max-width: 100%;
}

.header {
  height: 90px;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  background-color: var(--header-bg-color);
  align-items: center;
  padding: 0 25px;
  color: var(--body-color);
  font-size: 15px;
}
.header-left {
  display: flex;
  align-items: center;
}
.header-left svg {
  width: 22px;
  margin-right: 25px;
  flex-shrink: 0;
}
.logo {
  width: 34px;
  height: 34px;
  border: 5px solid var(--logo-color);
  border-radius: 50%;
  flex-shrink: 0;
}

.menu-item:hover {
  color: var(--logo-color);
}
.menu-item:not(:last-child) {
  margin-right: 20px;
}
.user-settings {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-shrink: 0;
}
.user-settings > * + * {
  margin-left: 18px;
}
.user-settings svg {
  width: 22px;
  flex-shrink: 0;
}

.menu-item.active {
  color: var(--logo-color);
}
.profil-menu {
  background-color: var(--white-to-gray);
  width: 100%;
}
.bgWhite {
  background-color: var(--white-to-gray);
}

.profile-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  position: relative;
}
.profile-container .main-info {
  width: 100%;
  padding: 25px;
  background-color: white;
}
.code-parrain {
  width: 100%;
  overflow-wrap: break-word;
  background-color: white;
}
