.notfound-root {
  padding-top: 80;
  padding-bottom: 80;
}

.notfound-inner {
  position: "relative";
}

.notfound-image {
  position: "absolute";
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0.75;
}

.notfound-content {
  padding-top: 220;
  position: "relative";
  z-index: 1;
}

.notfound-title {
  text-align: "center";
  font-weight: 900;
  font-size: 38;
}

.notfound-description {
  max-width: 540;
  margin: "auto";
  margin-top: 10px;
  margin-bottom: 10px * 1.5;
}
